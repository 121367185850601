import type { QiniuError, QiniuNetworkError, QiniuRequestError } from 'qiniu-js'
import { region, upload } from 'qiniu-js'
import { regionUphostMap } from 'qiniu-js/esm/config'
import type { UploadProgress } from 'qiniu-js/esm/upload'
import type { Observable, PartialObserver } from 'qiniu-js/esm/utils'
import type { reactive as VueReactive } from 'vue'
import { getSize } from '../util/byte'
import { includeAny } from '../util/string'

export interface TokenOption {
  resourceDirectoryType?: number
}
type GetUploadTokenApi = (file: File, tokenOptions?: TokenOption) => Promise<any>
type UploadObservable = PartialObserver<
  UploadProgress,
  QiniuError | QiniuRequestError | QiniuNetworkError,
  any
>

export function genUseQiniu({
  reactive,
  $error,
}: {
  reactive: typeof VueReactive
  $error: (_: string) => void
}) {
  return function useQiniu({
    size = 0,
    getUploadToken,
  }: {
    size?: number
    getUploadToken: GetUploadTokenApi
  }) {
    const uploadData = reactive({
      key: '',
      token: '',
      url: '',
      downloadUrl: '',
      regionUrl: region.z0 as keyof typeof regionUphostMap,
      uploadAbort: () => {},
    })
    let uploadObservable: ReturnType<typeof upload>
    let subscription: ReturnType<typeof Observable.prototype.subscribe>

    const beforeUpload = (file: File, tokenOptions: TokenOption = {}) => {
      if (size > 0 && file.size > size) {
        return Promise.reject(new Error(`上传的文件大小请不要超过${getSize(size)}`))
      }
      return getUploadToken(file, tokenOptions).then((res) => {
        uploadData.key = res.data.key
        uploadData.token = res.data.token
        uploadData.url = res.data.url
        uploadData.downloadUrl = res.data.downloadUrl ?? res.data.url
        uploadData.regionUrl = res.data.regionUrl || uploadData.regionUrl
      })
    }

    const doUpload = (file: File, observer: UploadObservable = { next: () => {} }) => {
      const originError = observer.error
      observer.error = (e) => {
        if (e.message.includes('incorrect region')) {
          for (const host in regionUphostMap) {
            if (
              includeAny(
                e.message,
                regionUphostMap[host as keyof typeof regionUphostMap]?.srcUphost as unknown as string,
              )
            ) {
              uploadData.regionUrl = host as keyof typeof regionUphostMap
              doUpload(file, { ...observer, error: originError! })
              return
            }
          }
        }
        originError?.(e)
      }
      // https://developer.qiniu.com/kodo/1283/javascript
      // https://github.com/qiniu/js-sdk
      uploadObservable = upload(
        file,
        uploadData.key,
        uploadData.token,
        {},
        { useCdnDomain: true, region: uploadData.regionUrl },
      )
      subscription = uploadObservable.subscribe(observer)
      uploadData.uploadAbort = () => subscription?.unsubscribe()
    }

    const uploadFile = (
      file: File,
      observer: UploadObservable = { next: () => {} },
      tokenOptions: TokenOption = {},
    ) => {
      return beforeUpload(file, tokenOptions)
        .then(() => {
          doUpload(file, observer)
          return true
        })
        .catch((e) => {
          $error(e.message || '上传失败')
          return false
        })
    }

    const uploadAbort = () => subscription?.unsubscribe()

    return { uploadData, uploadFile, uploadAbort }
  }
}
